import React from 'react';
import { Link } from 'react-router-dom';
import ChildEditDialog from './children/editModal';

export default function Backoffice() {
  return (
    <div className="backoffice">
      <h5>Backoffice</h5>
      <ul>
        <li>
          Novinky
          <ul>
            <li>
              <Link to="/backoffice/news">Seznam</Link>
            </li>
          </ul>
        </li>
        <li>
          Uživatelé
          <ul>
            <li>
              <Link to="/backoffice/users">Seznam</Link>
            </li>
            <li>
              <Link to="/backoffice/user-email-search">Výpis e-mailů</Link>
            </li>
          </ul>
        </li>
        <li>
          Děti
          <ul>
            <li>
              <ChildEditDialog />
            </li>
            <li>
              <Link to="/backoffice/children">Seznam</Link>
            </li>
            <li>
              <Link to="/backoffice/youtube-upload">Upload YouTube videí</Link>
            </li>
          </ul>
        </li>
        <li>
          Adopce
          <ul>
            <li>
              <Link to="/backoffice/adoption/post-list">Výpis na lepíčka</Link>
            </li>
          </ul>
        </li>
        <li>
          Transakce
          <ul>
            <li>
              <Link to="/backoffice/transactions">Seznam</Link>
            </li>
            <li>
              <Link to="/backoffice/transactions/summary">Roční přehledy</Link>
            </li>
            <li>
              <Link to="/backoffice/transactions/export">Export</Link>
            </li>
          </ul>
        </li>
        <li>
          Žádosti o platbu
          <ul>
            <li>
              <Link to="/backoffice/payment-requests/expired">Expirované</Link>
            </li>
            <li>
              <Link to="/backoffice/payment-requests/reminders">Urgence</Link>
            </li>
          </ul>
        </li>
        <li>
          E-maily
          <ul>
            <li>
              <Link to="/backoffice/email-templates">Šablony</Link>
            </li>
            <li>
              <Link to="/backoffice/email-queue">Fronta</Link>
            </li>
          </ul>
        </li>
        <li>
          Nastavení
          <ul>
            <li>
              <Link to="/backoffice/bank-certificates">
                Bankovní certifikáty
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
