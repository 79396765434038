import React from 'react';
import 'easymde/dist/easymde.min.css';
import { useQuery } from '@apollo/client';
import { BackToBackoffice } from '../links/BackToBackoffice';
import { gql } from '../../../__generated__';
import { preparePhotoUrl } from '../../../tools';
import NewsEditDialog from './editModal';

const GET_NEWS_LIST = gql(`
  query BackofficeNewsList {
    newsList {
      edges {
        node {      
          id
          title
          slug
          teaser
          mainPhoto
          content
          createdAt
          updatedAt
        }
      }
    }
  }
`);

export default function BackofficeNewsList() {
  const { loading, error, data } = useQuery(GET_NEWS_LIST);

  if (loading || !data || !data?.newsList) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  return (
    <>
      <BackToBackoffice />
      <h3>Novinky</h3>
      <NewsEditDialog title="" teaser="" mainPhoto={null} />
      <hr />
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Obrázek</th>
            <th scope="col">Titulek</th>
            <th scope="col">Akce</th>
          </tr>
        </thead>
        <tbody>
          {data.newsList.edges?.map((news) => (
            <tr key={news.node.id}>
              <th scope="row">
                {news.node.mainPhoto && (
                  <a href={preparePhotoUrl(news.node.mainPhoto)}>
                    <img
                      style={{
                        float: 'left',
                        maxWidth: '100px',
                        maxHeight: '100px',
                        width: 'auto',
                        height: 'auto',
                        margin: '3px 5px 3px 3px',
                      }}
                      src={`https://procontact.fra1.digitaloceanspaces.com/${news.node.mainPhoto}`}
                      alt=""
                    />
                  </a>
                )}
              </th>
              <th scope="row">{news.node.title}</th>
              <th scope="row">
                <NewsEditDialog
                  id={news.node.id}
                  title={news.node.title}
                  teaser={news.node.teaser}
                  mainPhoto={news.node.mainPhoto}
                />
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
