import React, { useEffect, useState } from 'react';
import 'easymde/dist/easymde.min.css';
import { Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IItemNode } from './list';
import { statusToString, txTypeToString } from './helpers';
import { gql } from '../../../__generated__';
import {
  AdminPaymentRequestQuery,
  GetTxDetailQuery,
} from '../../../__generated__/graphql';
import { formatDate } from '../../../tools';

const GET_TRANSACTION_DETAIL = gql(`
  query GetTxDetail($id: String) {
    adminTransactionDetail(id: $id) {
      id
      timestamp
      type
      source
      user {
        id
      }
      child {
        id
      }
      status
      value
      currency
      description
      bankReference
      bankSourceAccountPrefix
      bankSourceAccountNumber
      bankSourceAccountCode
      bankSourceAccountName
      bankMessage
      bankVariableSymbol
      bankConstantSymbol
      includeInCertificate
      paymentRequests {
        id
      }
    }
    usersList {
      edges {
        node {
          id
          mailingAddress {
            name
          }
          email
        }
      }
    }
    adminChildList {
      id
      fullNumber
      number
      name
      adoption {
        user {
          id
        }
      }
    }
  }
`);

const GET_USER_PAYMENT_REQUESTS = gql(`
  query AdminPaymentRequest($userId: String! $childId: String!) {
    adminPaymentRequest(userId: $userId, childId: $childId) {
      id
      paymentDate
      paid
      intervalDate {
        paymentInterval {
          price
        }
      }
    }
  }
`);

const TX_MUTATION = gql(`
  mutation CreateOrUpdateUserTx(
    $id: String
    $timestamp: DateTime!
    $type: String!
    $userId: UserId
    $childId: ChildId
    $value: Int!
    $currency: String!
    $description: String
    $status: String!
    $includeInCertificate: Boolean!
    $paymentRequests: [PaymentRequestId!]!
    $sendInfoEmail: Boolean
  ) {
    createOrUpdateTransaction(
      data: {
        id: $id
        timestamp: $timestamp
        type: $type
        userId: $userId
        childId: $childId
        value: $value
        currency: $currency
        description: $description
        status: $status
        includeInCertificate: $includeInCertificate
        paymentRequests: $paymentRequests
        sendInfoEmail: $sendInfoEmail
      }
    ) {
      id
    }
  }
`);

const DELETE_TX_MUTATION = gql(`
  mutation DeleteUserTransaction($id: String!) {
    deleteTransaction(data: { id: $id }) {
      success
    }
  }
`);

interface IChangeUserTransactionResponse {
  ChangeUserTransaction: IItemNode;
}

export interface IResponseModel {
  success: boolean;
}

type TransactionDetail = GetTxDetailQuery['adminTransactionDetail'];

const defaultTx = {
  id: crypto.randomUUID(),
  timestamp: new Date(),
  type: 'OTHER',
  source: 'MANUAL',
  status: 'ACCOUNTED',
  user: null,
  child: null,
  value: 0,
  currency: 'CZK',
  includeInCertificate: true,
  description: '',
} as TransactionDetail;

export default function BackofficeTransactionDetail(props: {
  openButtonTitle: string;
  id?: string;
}) {
  const [transaction, setTransaction] =
    useState<GetTxDetailQuery['adminTransactionDetail']>(defaultTx);
  const [userList, setUserList] = useState<
    GetTxDetailQuery['usersList']['edges']
  >([]);
  const [childList, setChildList] = useState<
    GetTxDetailQuery['adminChildList']
  >([]);
  const [userPaymentRequests, setUserPaymentRequests] = useState<
    AdminPaymentRequestQuery['adminPaymentRequest']
  >([]);
  const [paymentRequestIdHint, setPaymentRequestIdHint] = useState<
    string | undefined
  >(undefined);

  const [show, setShow] = useState(false);

  const [transactionMutation] = useMutation<IChangeUserTransactionResponse>(
    TX_MUTATION,
    {
      onCompleted: () => {
        setShow(false);
      },
      refetchQueries: ['UserTransactions', 'AdminPaymentRequest'],
    },
  );

  const [deleteTxMutation] = useMutation<IResponseModel>(DELETE_TX_MUTATION, {
    variables: {
      id: transaction.id,
    },
    onCompleted: () => {
      setShow(false);
    },
    refetchQueries: ['UserTransactions', 'AdminPaymentRequest'],
  });

  const [getTxDetail] = useLazyQuery(GET_TRANSACTION_DETAIL, {
    onCompleted(data) {
      setTransaction(data.adminTransactionDetail);
      setUserList(data.usersList.edges);
      setChildList(data.adminChildList);
    },
  });

  const [getPaymentRequests] = useLazyQuery(GET_USER_PAYMENT_REQUESTS, {
    onCompleted(data) {
      setUserPaymentRequests(data.adminPaymentRequest);
    },
  });

  useEffect(() => {
    if (show) {
      getTxDetail({ variables: { id: props.id } }).then();
    }
  }, [show]);

  useEffect(() => {
    const hintId = userPaymentRequests
      .filter(
        (paymentRequest) =>
          !paymentRequest.paid &&
          paymentRequest.intervalDate.paymentInterval.price ===
            transaction.value,
      )
      .pop()?.id;
    setPaymentRequestIdHint(hintId);
  }, [userPaymentRequests, transaction]);

  useEffect(() => {
    if (transaction.user && transaction.child) {
      getPaymentRequests({
        variables: {
          childId: transaction.child.id,
          userId: transaction.user.id,
        },
      });
    }
  }, [transaction]);

  const handleChildSelect = (childIdRaw: string) => {
    const childId = childIdRaw === 'none' ? null : childIdRaw;
    const userId = childList.find((child) => {
      return child.id === childId;
    })?.adoption?.user?.id;

    setTransaction({
      ...transaction,
      child: childId ? { id: childId } : null,
      user: userId ? { id: userId } : null,
    });
  };

  const handlePaymentRequestChange = (
    checked: boolean, // true for add
    paymentRequestId: string,
  ) => {
    if (checked) {
      // adding request
      if (
        !transaction.paymentRequests.find(
          (paymentRequest) => paymentRequest.id === paymentRequestId,
        )
      ) {
        // not there, so adding
        setTransaction({
          ...transaction,
          paymentRequests: [
            ...transaction.paymentRequests,
            { id: paymentRequestId },
          ],
        });
      }
    } else {
      // removing request
      if (
        transaction.paymentRequests.find(
          (paymentRequest) => paymentRequest.id === paymentRequestId,
        )
      ) {
        // exists, removing
        setTransaction({
          ...transaction,
          paymentRequests: transaction.paymentRequests.filter(
            (paymentRequest) => paymentRequest.id !== paymentRequestId,
          ),
        });
      }
    }
  };

  const handleSave = async (status?: string, sendInfoEmail?: boolean) => {
    await transactionMutation({
      variables: {
        id: transaction.id,
        timestamp: transaction.timestamp,
        type: transaction.type,
        userId: transaction.user?.id ? { id: transaction.user.id } : null,
        childId: transaction.child?.id ? { id: transaction.child.id } : null,
        value: transaction.value,
        currency: transaction.currency,
        description: transaction.description,
        status: status ? status : transaction.status,
        includeInCertificate: transaction.includeInCertificate,
        paymentRequests: transaction.paymentRequests?.map((paymentRequest) => {
          return {
            id: paymentRequest.id,
          };
        }),
        sendInfoEmail,
      },
    });
  };

  const handleClose = () => setShow(false);

  const handleDelete = async () => {
    await deleteTxMutation();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {props.openButtonTitle}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Úprava transakce</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <InputGroup className="mb-3">
              <InputGroup.Text>Zaúčtováno</InputGroup.Text>
              <Form.Control
                type="datetime-local"
                disabled={transaction.status === 'ACCOUNTED'}
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    timestamp: new Date(event.target.value),
                  });
                }}
                value={new Date(
                  new Date(transaction.timestamp).getTime() + 120 * 60 * 1000,
                )
                  .toISOString()
                  .slice(0, 19)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Typ</InputGroup.Text>
              <Form.Select
                onChange={(event) => {
                  setTransaction({ ...transaction, type: event.target.value });
                }}
                disabled={transaction.status === 'ACCOUNTED'}
                value={transaction.type}
              >
                <option value="ADOPTION">{txTypeToString('ADOPTION')}</option>
                <option value="DONATION">{txTypeToString('DONATION')}</option>
                <option value="GIFT">{txTypeToString('GIFT')}</option>
                <option value="OTHER">{txTypeToString('OTHER')}</option>
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Dítě</InputGroup.Text>
              <Form.Select
                disabled={transaction.status === 'ACCOUNTED'}
                onChange={(event) => handleChildSelect(event.target.value)}
                value={transaction.child?.id ?? 'none'}
              >
                <option key="none" value="none">
                  {' '}
                  -- žádné --{' '}
                </option>
                {childList.map((child) => (
                  <option key={child.id} value={child.id}>
                    {child.number} ({child.fullNumber}) &minus; {child.name}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Rodič</InputGroup.Text>
              <Form.Select
                disabled={
                  !!transaction.child || transaction.status === 'ACCOUNTED'
                }
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    user:
                      event.target.value === 'none'
                        ? null
                        : { id: event.target.value },
                  });
                }}
                value={transaction.user?.id ?? 'none'}
              >
                <option key="none" value="none">
                  {' '}
                  -- žádný --{' '}
                </option>
                {userList?.map((userEdge) => (
                  <option key={userEdge.node.id} value={userEdge.node.id}>
                    {userEdge.node.email}{' '}
                    {userEdge.node.mailingAddress?.name && (
                      <>&minus; {userEdge.node.mailingAddress?.name}</>
                    )}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Hodnota v CZK</InputGroup.Text>
              <Form.Control
                type="number"
                disabled={transaction.status === 'ACCOUNTED'}
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    value: parseInt(event.target.value),
                  });
                }}
                value={transaction.value}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Stav</InputGroup.Text>
              <Form.Control
                type="text"
                disabled
                readOnly
                value={statusToString(transaction.status)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Žádosti o platbu</InputGroup.Text>
              <Form.Control
                type="text"
                disabled
                readOnly
                value={`${transaction.paymentRequests?.length}×`}
              />
              <Table hover size="sm" style={{ width: '75%' }}>
                <thead>
                  <tr>
                    <th>Datum platby</th>
                    <th>Částka</th>
                    <th>Vybrat</th>
                  </tr>
                </thead>
                <tbody>
                  {userPaymentRequests.map((paymentRequest) => (
                    <tr
                      key={paymentRequest.id}
                      className={
                        paymentRequestIdHint === paymentRequest.id
                          ? 'table-warning'
                          : ''
                      }
                    >
                      <td>{formatDate(paymentRequest.paymentDate)}</td>
                      <td>
                        {paymentRequest.intervalDate.paymentInterval.price} Kč
                      </td>
                      <td>
                        {paymentRequest.paid ? (
                          'Zaplaceno'
                        ) : (
                          <Form.Check
                            type="checkbox"
                            id={`default-fsdfsd`}
                            value={paymentRequest.id}
                            disabled={transaction.status === 'ACCOUNTED'}
                            checked={transaction.paymentRequests
                              .map((paymentRequest) => paymentRequest.id)
                              .includes(paymentRequest.id)}
                            onChange={(event) =>
                              handlePaymentRequestChange(
                                event.target.checked,
                                paymentRequest.id,
                              )
                            }
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Popis</InputGroup.Text>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                disabled={transaction.status === 'ACCOUNTED'}
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    description: event.target.value,
                  });
                }}
                value={transaction.description ?? ''}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Potvrzení o daru</InputGroup.Text>
              <Form.Select
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    includeInCertificate: event.target.value == 'Ano',
                  });
                }}
                disabled={transaction.status === 'ACCOUNTED'}
                value={transaction.includeInCertificate ? 'Ano' : 'Ne'}
              >
                <option value="Ano">Započítat</option>
                <option value="Ne">Nezapočítat</option>
              </Form.Select>
            </InputGroup>
          </>
        </Modal.Body>
        <Modal.Footer>
          {transaction.source === 'MANUAL' && transaction.id && (
            <Button
              variant="danger"
              onClick={handleDelete}
              disabled={transaction.status === 'ACCOUNTED'}
            >
              Smazat
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Zavřít
          </Button>
          {transaction.status !== 'ACCOUNTED' && (
            <>
              <Button variant="primary" onClick={() => handleSave()}>
                Uložit
              </Button>
              <Button
                variant={transaction.user ? 'outline-success' : 'success'}
                onClick={() => handleSave('ACCOUNTED', false)}
              >
                Zaúčtovat
              </Button>
              {transaction.user && (
                <Button
                  variant="success"
                  onClick={() => handleSave('ACCOUNTED', true)}
                >
                  Zaúčtovat + email
                </Button>
              )}
            </>
          )}
          {transaction.status === 'ACCOUNTED' && (
            <Button variant="danger" onClick={() => handleSave('UNACCOUNTED')}>
              Zrušit zaúčtování
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
