import { gql } from '../../../__generated__';
import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CHANGE_EMAIL_MUTATION = gql(`
  mutation ChangeEmailMutation($userId: String!, $email: String!) {
    backofficeEmailChange(userId: $userId, email: $email) {
      success
    }
  }
`);

export default function UserEmailChange(props: {
  userId: string;
  email: string;
}) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState<string>(props.email);
  const [changeEmailMutation] = useMutation(CHANGE_EMAIL_MUTATION);

  function onSubmit() {
    changeEmailMutation({
      refetchQueries: ['getUserDetail'],
      variables: {
        userId: props.userId,
        email,
      },
    })
      .catch(console.error)
      .then(() => {
        navigate(`/backoffice/user/${email}`);
        setShow(false);
      });
  }

  return (
    <>
      {!show && (
        <Button variant="primary" onClick={() => setShow(true)}>
          Upravit
        </Button>
      )}

      {show && (
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Úprava emailu uživatele</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Text>Email</InputGroup.Text>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                value={email}
              />
            </InputGroup>
            <Button variant="primary" onClick={onSubmit}>
              Uložit
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
