import { useQuery } from '@apollo/client';
import React from 'react';
import { BackToBackoffice } from '../links/BackToBackoffice';
import ExcelExport from '../../../components/common/ExcelExport';
import { gql } from '../../../__generated__';

const QUERY = gql(`
  query UserTransactionsExport {
    transactions {
      edges {
        node {
          id
          timestamp
          type
          source
          user {
            id
            email
            mailingAddress {
              name
            }
          }
          child {
            id
            name
            number
            fullNumber
          }
          status
          value
          currency
          description
          bankReference
          bankSourceAccountPrefix
          bankSourceAccountNumber
          bankSourceAccountCode
          bankSourceAccountName
          bankMessage
          bankVariableSymbol
          bankConstantSymbol
          includeInCertificate
        }
      }
    }
  }
`);

export default function BackofficeTransactionsExport() {
  const { loading, error, data } = useQuery(QUERY);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const transactions = data?.transactions?.edges ?? [];

  const result = transactions.map((txNode) => {
    const line = {
      ...txNode.node,
      user: txNode.node.user?.email,
      child: txNode.node.child?.fullNumber,
      childNumber: txNode.node.child?.number,
      childName: txNode.node.child?.name,
    };
    delete line.__typename;
    return line;
  });

  return (
    <div>
      <BackToBackoffice />
      <h3>Transakce</h3>
      <p>
        <ExcelExport data={result} fileName="tx_list" />
      </p>
    </div>
  );
}
