import React, { useRef } from 'react';
import { BackToBackoffice } from '../links/BackToBackoffice';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { gql } from '../../../__generated__';

const GET_POST_LIST = gql(`
  query GetPostList {
    postList {
      userId
      childNumbers
      address {
        id
        name
        street
        city
        zip
        state
        phone
      }
    }
  }
`);

export function PostList() {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  const { loading, error, data } = useQuery(GET_POST_LIST);

  if (loading || !data) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  return (
    <div>
      <BackToBackoffice />
      <h3>Výpis na lepíčka</h3>
      <Button onClick={() => reactToPrintFn()}>Vytisknout...</Button>
      <hr />
      <div ref={contentRef}>
        <div className="container">
          <div className="row row-cols-2">
            {data.postList.map((item) => (
              <div
                className="col"
                key={item.address.id}
                style={{ breakInside: 'avoid' }}
              >
                <div style={{ textAlign: 'left' }} className="text-muted">
                  ({item.childNumbers.replaceAll(',', ', ')})
                </div>
                <pre
                  style={{
                    textAlign: 'left',
                    fontSize: 'larger',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {item.address.name}
                  <br />
                  {item.address.street}
                  <br />
                  {item.address.zip}&nbsp;&nbsp;{item.address.city}
                  <br />
                  {item.address.state}
                </pre>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
