import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '../../__generated__';

const GET_ADOPTION_OFFER = gql(`
  query RandomAdoptionOffer($count: Int) {
    randomAdoptionOffer(count: $count) {
      name
      fullNumber
      mainPhoto
    }
  }
`);

interface IChild {
  name: string;
  fullNumber: string;
  mainPhoto?: string | null;
}

export default function AdoptionOffer() {
  const { loading, error, data } = useQuery(GET_ADOPTION_OFFER, {
    variables: { count: 50 },
  });

  const [offer, setOffer] = useState<IChild | null>(null);

  const pickRandomOffer = () => {
    if (data && data.randomAdoptionOffer) {
      const randomChild =
        data.randomAdoptionOffer[
          Math.floor(Math.random() * data.randomAdoptionOffer.length)
        ];
      setOffer(randomChild);
    }
  };

  useEffect(() => {
    pickRandomOffer();
    setInterval(() => {
      pickRandomOffer();
      setInterval(() => {
        pickRandomOffer();
      }, 20000);
    }, 20000);
  }, [data]);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba : {error.message}</p>;
  if (data?.randomAdoptionOffer === null) return <></>;

  if (!offer) {
    return <></>;
  }

  return (
    <div
      className="menu-block d-none d-md-block"
      style={{ position: 'relative' }}
    >
      <img
        src="/images/okraj.gif"
        className="adoption-offer"
        style={{
          position: 'relative',
          width: '100%',
        }}
        alt=""
      ></img>
      <div
        style={{
          position: 'absolute',
          top: '-3%',
          width: '100%',
        }}
      >
        <Link to={'/child/' + offer.fullNumber}>
          <img
            style={{
              maxWidth: '70%',
              maxHeight: '70%',
              margin: '15%',
              border: 'solid 1px black',
            }}
            className="adoption-offer-image"
            src={
              'https://procontact.fra1.digitaloceanspaces.com/' +
              offer.mainPhoto
            }
            alt=""
          />
        </Link>
      </div>
      <div style={{ clear: 'both' }}></div>
    </div>
  );
}
