import React, { useEffect, useMemo, useState } from 'react';
import SimpleMdeReact from 'react-simplemde-editor';
import SimpleMDE from 'easymde';
import 'easymde/dist/easymde.min.css';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ImageUploader } from '../../../components/common/ImageUploader';
import { gql } from '../../../__generated__';

const GET_CHILD_DETAILS = gql(`
  query AdminGetChildInfo($id: String!) {
    adminGetChildInfo(id: $id) {
      id
      name
      number
      mainPhoto
      description
      sex
      state
      birthDate
      coordinator {
        id
      }
      school {
        id
      }
      inSchoolAfter
      motherName
      fatherName
    }
  }
`);

const GET_OTHER_DETAILS = gql(`
  query adminGetOtherData {
    listCoordinators {
      id
      name
      sign
    }
    listSchools {
      id
      name
    }
  }
`);

const CHANGE_CHILD = gql(`
  mutation CreateOrUpdateChild(
    $id: String
    $name: String!
    $number: String!
    $mainPhoto: String!
    $description: String!
    $sex: String!
    $birthDate: String!
    $coordinatorId: String!
    $schoolId: String!
    $inSchoolAfter: String!
    $motherName: String!
    $fatherName: String!
  ) {
    createOrUpdateChild(
      input: {
        id: $id
        name: $name
        number: $number
        mainPhoto: $mainPhoto
        description: $description
        sex: $sex
        birthDate: $birthDate
        coordinatorId: $coordinatorId
        schoolId: $schoolId
        inSchoolAfter: $inSchoolAfter
        motherName: $motherName
        fatherName: $fatherName
      }
    ) {
      id
    }
  }
`);

const DELETE_CHILD = gql(`
  mutation DeleteChild($id: String!) {
    deleteChild(id: $id) {
      success
    }
  }
`);

export default function ChildEditDialog(props: { id?: string }) {
  const [id, setId] = useState(props.id);
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('0');
  const [mainPhoto, setMainPhoto] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string>('');
  const [sex, setSex] = useState<'M' | 'F'>('M');
  const [birthDate, setBirthDate] = useState<string>('');
  const [coordinatorId, setCoordinatorId] = useState<string | null>(null);
  const [schoolId, setSchoolId] = useState<string | null>(null);
  const [inSchoolAfter, setInSchoolAfter] = useState<string>('');
  const [motherName, setMotherName] = useState<string>('');
  const [fatherName, setFatherName] = useState<string>('');

  const [show, setShow] = useState(false);

  const [getInfo, { loading, data }] = useLazyQuery(GET_CHILD_DETAILS, {
    variables: {
      id: props.id ?? '',
    },
    onCompleted: (data) => {
      const child = data.adminGetChildInfo;
      setId(child.id);
      setName(child.name);
      setNumber(child.number);
      setMainPhoto(child.mainPhoto ?? '');
      setDescription(child.description);
      setSex(child.sex === 'M' ? 'M' : 'F');
      setBirthDate(child.birthDate ?? '');
      if (child.coordinator?.id) {
        setCoordinatorId(child.coordinator.id);
      }
      if (child.school?.id) {
        setSchoolId(child.school.id);
      }
      setInSchoolAfter(child.inSchoolAfter);
      setMotherName(child.motherName ?? '');
      setFatherName(child.fatherName ?? '');
    },
  });

  const { data: otherData } = useQuery(GET_OTHER_DETAILS, {
    onCompleted(data) {
      if (!coordinatorId && data.listCoordinators[0]) {
        setCoordinatorId(data.listCoordinators[0].id);
      }
      if (!schoolId && data.listSchools[0]) {
        setSchoolId(data.listSchools[0].id);
      }
    },
  });

  const [changeChildMutation] = useMutation(CHANGE_CHILD, {
    variables: {
      id,
      name,
      number,
      mainPhoto: mainPhoto ?? '',
      description,
      sex,
      birthDate,
      coordinatorId: coordinatorId ?? '',
      schoolId: schoolId ?? '',
      inSchoolAfter,
      motherName,
      fatherName,
    },
    onCompleted: () => {
      setShow(false);
    },
    refetchQueries: ['BackofficeChildList', 'child'],
  });

  const [deleteChildMutation] = useMutation(DELETE_CHILD, {
    variables: {
      id: props.id ?? '',
    },
    onCompleted: () => {
      setShow(false);
    },
    refetchQueries: ['BackofficeNewsList', 'child'],
  });

  useEffect(() => {
    if (show && props.id) {
      getInfo();
    }
  }, [show]);

  const handleSave = async () => {
    await changeChildMutation();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    deleteChildMutation();
  };

  const handleRemoveImage = () => {
    setMainPhoto(undefined);
  };

  const editorOptions = useMemo(() => {
    return {
      spellChecker: false,
      status: false,
      sideBySideFullscreen: false,
      maxHeight: '160px',
    } as SimpleMDE.Options;
  }, []);

  return (
    <>
      {id ? (
        <Button variant="primary" onClick={handleShow}>
          Upravit
        </Button>
      ) : (
        <a href="#" onClick={handleShow}>
          Vytvořit
        </a>
      )}

      {show && (
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Úprava údajů dítěte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Row>
                <Col xs={4}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Číslo</InputGroup.Text>
                    <Form.Control
                      type="number"
                      readOnly={!!props.id}
                      onChange={(event) => {
                        setNumber(event.target.value);
                      }}
                      value={number}
                    />
                  </InputGroup>
                </Col>
                <Col xs={8}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Jméno</InputGroup.Text>
                    <Form.Control
                      type="text"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      value={name}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Koordinátor</InputGroup.Text>
                    <Form.Select
                      onChange={(event) => {
                        setCoordinatorId(event.target.value);
                      }}
                      value={coordinatorId ?? ''}
                    >
                      {otherData?.listCoordinators.map((coordinator) => (
                        <option key={coordinator.id} value={coordinator.id}>
                          {coordinator.name} ({coordinator.sign})
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col xs={8}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Datum narození</InputGroup.Text>
                    <Form.Control
                      type="text"
                      onChange={(event) => {
                        setBirthDate(event.target.value);
                      }}
                      value={birthDate}
                    />
                  </InputGroup>
                </Col>
                <Col xs={4}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Pohlaví</InputGroup.Text>
                    <Form.Select
                      onChange={(event) => {
                        if (event.target.value === 'M') {
                          setSex('M');
                        } else {
                          setSex('F');
                        }
                      }}
                      value={sex}
                    >
                      <option key="M" value="M">
                        muž
                      </option>
                      <option key="F" value="F">
                        žena
                      </option>
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col xs={4}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Obrázek</InputGroup.Text>
                    <div>
                      <div>
                        {mainPhoto && (
                          <>
                            <img
                              style={{
                                maxWidth: '100px',
                                maxHeight: '100px',
                                width: 'auto',
                                height: 'auto',
                                margin: '3px 5px 3px 3px',
                              }}
                              src={`https://procontact.fra1.digitaloceanspaces.com/${mainPhoto}`}
                              alt=""
                            />
                            <Button
                              variant="outline-danger"
                              onClick={handleRemoveImage}
                            >
                              Odebrat obrázek
                            </Button>
                          </>
                        )}
                      </div>
                      <ImageUploader
                        type="child"
                        childNumber={number}
                        onUploadFinished={setMainPhoto}
                      ></ImageUploader>
                    </div>
                  </InputGroup>
                </Col>
                <Col xs={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Popis</InputGroup.Text>
                    <SimpleMdeReact
                      value={description}
                      onChange={(data) => {
                        setDescription(data);
                      }}
                      options={editorOptions}
                    />
                  </InputGroup>
                </Col>
                <Col xs={6}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Škola</InputGroup.Text>
                    <Form.Select
                      onChange={(event) => {
                        setSchoolId(event.target.value);
                      }}
                      value={schoolId ?? ''}
                    >
                      {otherData?.listSchools.map((school) => (
                        <option key={school.id} value={school.id}>
                          {school.name}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col xs={6}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Ve škole od</InputGroup.Text>
                    <Form.Control
                      type="text"
                      onChange={(event) => {
                        setInSchoolAfter(event.target.value);
                      }}
                      value={inSchoolAfter}
                    />
                  </InputGroup>
                </Col>
                <Col xs={6}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Jméno matky</InputGroup.Text>
                    <Form.Control
                      type="text"
                      onChange={(event) => {
                        setMotherName(event.target.value);
                      }}
                      value={motherName}
                    />
                  </InputGroup>
                </Col>
                <Col xs={6}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Jméno otce</InputGroup.Text>
                    <Form.Control
                      type="text"
                      onChange={(event) => {
                        setFatherName(event.target.value);
                      }}
                      value={fatherName}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </>
          </Modal.Body>
          <Modal.Footer>
            {data?.adminGetChildInfo.state === 'for_adoption' && (
              <Button variant="danger" onClick={handleDelete}>
                Smazat
              </Button>
            )}
            <Button variant="secondary" onClick={handleClose}>
              Zavřít
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Uložit změny
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
