import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '../../../__generated__';

interface ICancelModalProps {
  show: boolean;
  onHide: () => void;
  childFullNumber: string;
  adoptionId: string;
}

const ADOPTION_CANCEL_MUTATION = gql(`
  mutation CancelAdoption($adoptionId: String!, $keep: Boolean!) {
    backofficeCancelAdoption(adoptionId: $adoptionId, keep: $keep) {
      success
    }
  }
`);

function AdoptionCancelModal(props: ICancelModalProps) {
  const [cancelAdoptionMutation] = useMutation(ADOPTION_CANCEL_MUTATION, {
    onCompleted: () => {
      props.onHide();
    },
    refetchQueries: ['getAdoptionDetails'],
  });

  const handlerCancelAdoption = (keep: boolean) => {
    console.log('Voláme mutaci zruseni adopce ' + props.adoptionId);
    cancelAdoptionMutation({
      variables: {
        adoptionId: props.adoptionId,
        keep,
      },
    });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Zrušení adopce {props.childFullNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Opravdu si přejete zrušit adopci {props.childFullNumber}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Ne</Button>
        <Button
          variant="outline-danger"
          onClick={() => handlerCancelAdoption(true)}
        >
          Ano, dítě ponechat v nabídce.
        </Button>
        <Button variant="danger" onClick={() => handlerCancelAdoption(false)}>
          Ano, dítě umístit do neviditelných.
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

interface ICancelButtonProps {
  childFullNumber: string;
  adoptionId: string;
}

export function AdoptionCancelButton(props: ICancelButtonProps) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="danger" onClick={() => setModalShow(true)}>
        Zrušit adopci
      </Button>

      <AdoptionCancelModal
        childFullNumber={props.childFullNumber}
        adoptionId={props.adoptionId}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export {};
