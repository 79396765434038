import React from 'react';
import { useState } from 'react';
import ChildPreview from './ChildPreview';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Pagination from './Pagination';

export interface IChild {
  number: number;
  fullNumber: string;
  name: string;
  top: boolean;
  description: string;
  state: string;
  birthDate: string;
  mainPhoto: string;
}

export interface IChildNode {
  node: IChild;
}

const GET_CHILDREN_LIST = gql`
  query childList($state: String!) {
    childList(state: $state) {
      edges {
        node {
          name
          number
          top
          fullNumber
          description
          mainPhoto
          state
        }
      }
    }
  }
`;

export default function ChildrenList() {
  const itemsPerPage = 5;

  const { page: currentPage = 1, listType } = useParams();
  const pageNumber = Number(currentPage) - 1;
  const navigate = useNavigate();
  const [itemOffset, setItemOffset] = useState(pageNumber * itemsPerPage);

  const { loading, error, data } = useQuery(GET_CHILDREN_LIST, {
    variables: { state: listType },
  });

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const children = data.childList.edges;
  if (children === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = children.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(children.length / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % children.length;
    setItemOffset(newOffset);
    navigate(`/children/${listType}/page/` + (Number(event.selected) + 1));
  };

  const getTitle = () => {
    let title = 'Děti';
    if (listType === 'for_adoption') {
      title = 'Děti k adoptování';
    } else if (listType === 'reserved') {
      title = 'Rezervované děti';
    } else if (listType === 'adopted') {
      title = 'Adoptované děti';
    }
    return title;
  };

  return (
    <div>
      <h3>{getTitle()}</h3>
      <Pagination
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        pageNumber={pageNumber}
        pageUrlPrefix={`/children/${listType}/page`}
      ></Pagination>
      <div>
        {currentItems.map((child: IChildNode) => (
          <div className="" key={child.node.number}>
            <ChildPreview key={child.node.number} data={child.node} />
          </div>
        ))}
      </div>
      <div className="pt-2">
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          pageNumber={pageNumber}
          pageUrlPrefix={`/children/${listType}/page`}
        ></Pagination>
      </div>
    </div>
  );
}
