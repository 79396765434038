import React from 'react';
import { useState } from 'react';
import ChildPreview from './ChildPreview';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Pagination from './Pagination';

export interface IChild {
  number: number;
  fullNumber: string;
  name: string;
  top: boolean;
  description: string;
  state: string;
  birthDate: string;
  mainPhoto: string;
}

export interface IChildNode {
  node: IChild;
}

const GET_CHILDREN_SEARCH = gql`
  query childSearch($text: String!) {
    childSearch(text: $text) {
      edges {
        node {
          name
          number
          fullNumber
          top
          description
          mainPhoto
          state
        }
      }
    }
  }
`;

export default function ChildrenSearch() {
  const itemsPerPage = 5;

  const { page: currentPage = 1 } = useParams();
  const [searchParams] = useSearchParams();
  const text = searchParams.get('text') || '';
  const pageNumber = Number(currentPage) - 1;
  const navigate = useNavigate();
  const [itemOffset, setItemOffset] = useState(pageNumber * itemsPerPage);

  const { loading, error, data } = useQuery(GET_CHILDREN_SEARCH, {
    variables: { text },
  });

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const children = data.childSearch.edges;
  if (children === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = children.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(children.length / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % children.length;
    setItemOffset(newOffset);
    navigate(
      `/children/search/page/` + (Number(event.selected) + 1) + '?text=' + text,
    );
  };

  return (
    <div>
      <h3>Výsledek vyhledávání</h3>

      <Pagination
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        pageNumber={pageNumber}
        pageUrlPrefix={`/children/search/page`}
        pageUrlSuffix={'?text=' + text}
      ></Pagination>
      <div>
        {currentItems.map((child: IChildNode) => (
          <div className="" key={child.node.number}>
            <ChildPreview key={child.node.number} data={child.node} />
          </div>
        ))}
      </div>
      <div className="pt-2">
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          pageNumber={pageNumber}
          pageUrlPrefix={`/children/search/page`}
          pageUrlSuffix={'?text=' + text}
        ></Pagination>
      </div>
    </div>
  );
}
