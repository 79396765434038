import React, { useCallback, useMemo, useState } from 'react';
import SimpleMdeReact from 'react-simplemde-editor';
import SimpleMDE from 'easymde';
import 'easymde/dist/easymde.min.css';
import { Button, Form, Modal } from 'react-bootstrap';
import { gql, useMutation } from '@apollo/client';
import { ImageUploader } from '../../../components/common/ImageUploader';

const CHANGE_NEWS = gql`
  mutation CreateOrUpdateNews(
    $id: String
    $title: String!
    $teaser: String!
    $mainPhoto: String
  ) {
    createOrUpdateNews(
      input: { id: $id, title: $title, teaser: $teaser, mainPhoto: $mainPhoto }
    ) {
      id
    }
  }
`;

const DELETE_NEWS = gql`
  mutation DeleteNews($id: String!) {
    deleteNews(id: $id) {
      success
    }
  }
`;

interface INews {
  id?: string;
  title: string;
  teaser: string;
  mainPhoto: string | null | undefined;
}

export default function NewsEditDialog(props: INews) {
  const [id, setId] = useState(props.id);
  const [title, setTitle] = useState(props.title);
  const [teaser, setTeaser] = useState(props.teaser);
  const [mainPhoto, setMainPhoto] = useState(props.mainPhoto);

  const [show, setShow] = useState(false);

  const [changeNewsMutation] = useMutation(CHANGE_NEWS, {
    variables: {
      id,
      title,
      teaser,
      mainPhoto,
    },
    onCompleted: () => {
      setShow(false);
    },
    refetchQueries: ['BackofficeNewsList'],
  });

  const [deleteNewsMutation] = useMutation(DELETE_NEWS, {
    variables: {
      id,
    },
    onCompleted: () => {
      setShow(false);
    },
    refetchQueries: ['BackofficeNewsList'],
  });

  const handleSave = async () => {
    await changeNewsMutation();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    deleteNewsMutation();
  };

  const onChangeBody = useCallback((value: string) => {
    setTeaser(value);
  }, []);

  const onChangeSubject = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setTitle(event.target.value);
  };

  const handleRemoveImage = () => {
    setMainPhoto(undefined);
  };

  const editorOptions = useMemo(() => {
    return {
      spellChecker: false,
      status: false,
      sideBySideFullscreen: false,
    } as SimpleMDE.Options;
  }, []);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {id ? 'Upravit' : 'Vytvořit'}
      </Button>

      {show && (
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Úprava novinky</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form.Group className="mb-3">
                <Form.Label>Předmět</Form.Label>
                <Form.Control
                  type="text"
                  onChange={onChangeSubject}
                  value={title}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Tělo</Form.Label>
                <SimpleMdeReact
                  value={teaser}
                  onChange={onChangeBody}
                  options={editorOptions}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Obrázek</Form.Label>
                <div>
                  <div>
                    {mainPhoto && (
                      <>
                        <img
                          style={{
                            maxWidth: '100px',
                            maxHeight: '100px',
                            width: 'auto',
                            height: 'auto',
                            margin: '3px 5px 3px 3px',
                          }}
                          src={`https://procontact.fra1.digitaloceanspaces.com/${mainPhoto}`}
                          alt=""
                        />
                        <Button
                          variant="outline-danger"
                          onClick={handleRemoveImage}
                        >
                          Odebrat obrázek
                        </Button>
                      </>
                    )}
                  </div>
                  <ImageUploader
                    type="news"
                    onUploadFinished={setMainPhoto}
                  ></ImageUploader>
                </div>
              </Form.Group>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleDelete}>
              Smazat
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Zavřít
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Uložit změny
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
