import { gql, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Pagination from '../../../components/Pagination';
import BackofficeTransactionDetail from './detail';
import { statusToString, txTypeToString } from './helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { BackToBackoffice } from '../links/BackToBackoffice';
import { InputGroup } from 'react-bootstrap';

const QUERY = gql`
  query UserTransactions($filterByEmail: String, $filterByType: String) {
    transactions(email: $filterByEmail, type: $filterByType) {
      edges {
        node {
          id
          timestamp
          type
          source
          user {
            id
            username
            email
            mailingAddress {
              name
            }
          }
          child {
            id
            name
            fullNumber
          }
          status
          value
          currency
          description
          bankReference
          bankSourceAccountPrefix
          bankSourceAccountNumber
          bankSourceAccountCode
          bankSourceAccountName
          bankMessage
          bankVariableSymbol
          bankConstantSymbol
          includeInCertificate
          paymentRequests {
            id
          }
        }
      }
    }
  }
`;

export interface IItemNode {
  id: string;
  timestamp: string;
  type: string;
  source: string;
  user: IUser | null;
  child: ISimpleChild | null;
  status: string;
  value: number;
  currency: string;
  description: string;
  bankReference?: string;
  bankSourceAccountPrefix?: string;
  bankSourceAccountNumber?: string;
  bankSourceAccountCode?: string;
  bankSourceAccountName?: string;
  bankMessage?: string;
  bankVariableSymbol?: string;
  bankConstantSymbol?: string;
  includeInCertificate: boolean;
  paymentRequests?: { id: string }[];
}

export interface IItemEdge {
  node: IItemNode;
}

export interface IUser {
  id: string;
  username: string;
  mailingAddress?: {
    name: string;
  };
  email: string;
}

interface ISimpleChild {
  id: string;
  name: string;
  fullNumber: string;
}

export default function BackofficeTransactionsList() {
  const itemsPerPage = 30;

  const { page: currentPage = 1 } = useParams();
  const pageNumber = Number(currentPage) - 1;
  const navigate = useNavigate();
  const [itemOffset, setItemOffset] = useState(pageNumber * itemsPerPage);

  const [filterByEmail, setFilterByEmail] = useState<string>('');
  const [filterByType, setFilterByType] = useState<string>('');

  const [callQuery, { loading, error, data, refetch }] = useLazyQuery(QUERY);

  const callQueryWithFresh = () => {
    setItemOffset(0);
    callQuery({
      variables: {
        filterByEmail: filterByEmail === '' ? null : filterByEmail,
        filterByType: filterByType === '' ? null : filterByType,
      },
    });
  };

  useEffect(() => {
    callQueryWithFresh();
  }, [filterByType]);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const transactions = data?.transactions?.edges ?? [];

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = transactions.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(transactions.length / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % transactions.length;
    setItemOffset(newOffset);
    navigate(`/backoffice/transactions/page/` + (Number(event.selected) + 1));
  };

  const filterEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterByEmail(event.target.value);
  };

  const filterTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterByType(event.target.value);
  };

  return (
    <div>
      <BackToBackoffice />
      <h3>Transakce</h3>
      <p>
        <BackofficeTransactionDetail openButtonTitle="Vytvořit" />
      </p>

      <div>
        <h6>Filtry</h6>
        <Form>
          <InputGroup className="mb-3">
            <InputGroup.Text>E-mail</InputGroup.Text>
            <Form.Control
              type="text"
              name="email"
              onChange={filterEmailChange}
              onBlur={() => callQueryWithFresh()}
              placeholder="E-mail"
              value={filterByEmail}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Typ</InputGroup.Text>
            <Form.Select onChange={filterTypeChange} value={filterByType}>
              <option value=""> -- </option>
              <option value="ADOPTION">{txTypeToString('ADOPTION')}</option>
              <option value="DONATION">{txTypeToString('DONATION')}</option>
              <option value="GIFT">{txTypeToString('GIFT')}</option>
              <option value="OTHER">{txTypeToString('OTHER')}</option>
            </Form.Select>
          </InputGroup>
        </Form>
      </div>

      {}
      <Pagination
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        pageNumber={pageNumber}
        pageUrlPrefix={`/backoffice/transactions`}
      ></Pagination>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Zaúčtováno</th>
            <th scope="col">Typ / Zdroj</th>
            <th scope="col">Dítě</th>
            <th scope="col">Rodič</th>
            <th className="text-end" scope="col">
              Hodnota
            </th>
            <th scope="col">Stav / Potvrzení</th>
            <th scope="col">Popis</th>
            <th scope="col">Info</th>
            <th scope="col">Akce</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((itemNode: IItemEdge) => (
            <tr
              key={itemNode.node.id}
              style={
                itemNode.node.status === 'CANCELED'
                  ? { textDecoration: 'line-through' }
                  : {}
              }
              className={
                itemNode.node.status === 'UNACCOUNTED'
                  ? 'table-danger'
                  : itemNode.node.status === 'ACCOUNTED'
                    ? 'table-success'
                    : 'table-light'
              }
            >
              <td scope="row">
                {new Date(itemNode.node.timestamp).toLocaleString()}
              </td>
              <td scope="row">
                {txTypeToString(itemNode.node.type)}
                <hr />
                {itemNode.node.source === 'BANK_ACCOUNT' ? 'API' : 'WEB'}
              </td>
              <td scope="row">
                {itemNode.node.child && (
                  <a
                    href={`/child/${itemNode.node.child?.fullNumber}`}
                    style={{ color: 'darkgreen' }}
                  >
                    {itemNode.node.child?.fullNumber}
                    <br />
                    {itemNode.node.child?.name}
                  </a>
                )}
              </td>
              <td scope="row">
                {itemNode.node.user?.email}
                {itemNode.node.user?.mailingAddress?.name && (
                  <span>
                    <br />
                    {itemNode.node.user?.mailingAddress?.name}
                  </span>
                )}
              </td>
              <td className="text-end" scope="row">
                {itemNode.node.value} {itemNode.node.currency}
              </td>
              <td scope="row">
                {statusToString(itemNode.node.status)} <hr />{' '}
                {itemNode.node.includeInCertificate ? (
                  'Ano'
                ) : (
                  <p className="text-primary fw-bold">Ne</p>
                )}
              </td>
              <td scope="row">
                {itemNode.node.description ? (
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    title={itemNode.node.description}
                  />
                ) : (
                  ''
                )}
              </td>
              <td scope="row">
                {itemNode.node.bankSourceAccountName}
                <br />
                {itemNode.node.bankSourceAccountPrefix && (
                  <span>{itemNode.node.bankSourceAccountPrefix}&minus;</span>
                )}
                {itemNode.node.bankSourceAccountNumber}
                {itemNode.node.bankSourceAccountCode && (
                  <span>/{itemNode.node.bankSourceAccountCode}</span>
                )}
                {itemNode.node.bankVariableSymbol && (
                  <span>
                    <br />
                    VS: {itemNode.node.bankVariableSymbol}
                  </span>
                )}
              </td>
              <td scope="row">
                <BackofficeTransactionDetail
                  key={itemNode.node.id}
                  openButtonTitle="Upravit"
                  id={itemNode.node.id}
                ></BackofficeTransactionDetail>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pt-2">
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          pageNumber={pageNumber}
          pageUrlPrefix={`/backoffice/transactions`}
        ></Pagination>
      </div>
    </div>
  );
}
