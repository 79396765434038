import React from 'react';
import ImpersonationButton from '../../../components/Impersonation/Impersonation';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { gql } from '../../../__generated__';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { getBackendUrl, photoUrl } from '../../../tools';
import { AuthenticatedLink } from '../../../components/AuthenticatedLink';
import UserEmailChange from './UserEmailChange';

const GET_USER_DETAIL = gql(`
  query getUserDetail($email: String!) {
    userDetail(email: $email) {
      id
      email
      phone
      role
      legacyId
      adoptions {
        id
        child {
          name
          fullNumber
          mainPhoto
        }
        paymentInterval {
          treaty
        }
        lastPayment
        nextPayment
      }
      mailingAddress {
        name
        street
        city
        zip
        state
        phone
      }
      contractAddress {
        name
        street
        city
        zip
        state
        phone
      }
    }
    backofficeGiftCertificates(email: $email) {
      id
      year
      downloadAllowed
      price
    }
  }
`);

const DELETE_CERT_MUTATION = gql(`
  mutation DeleteCertMutation($id: String!) {
    backofficeRemoveGiftCertificate(id: $id) {
      success
    }
  }
`);

export default function UserDetail() {
  const { email } = useParams();
  const { loading, error, data } = useQuery(GET_USER_DETAIL, {
    variables: { email: email?.toLowerCase() ?? '' },
  });

  const [deleteCertMutation] = useMutation(DELETE_CERT_MUTATION);

  const handleClickDeleteCert = (certId: string) => {
    deleteCertMutation({
      variables: { id: certId },
      refetchQueries: ['getUserDetail'],
    }).then((r) => console.log(r.data));
  };

  if (loading || !data) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  return (
    <div>
      <Container>
        <Row>
          <Col md={6}>
            <h3>Uživatel {data.userDetail.email}</h3>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Detail</Card.Title>
                <Card.Text>
                  <strong>ID:</strong> {data.userDetail.id}
                </Card.Text>
                <Card.Text>
                  <strong>Email:</strong> {data.userDetail.email}&nbsp;
                  <UserEmailChange
                    userId={data.userDetail.id}
                    email={data.userDetail.email}
                  />
                </Card.Text>
                <Card.Text>
                  <strong>Telefon:</strong> {data.userDetail.phone}
                </Card.Text>
                <Card.Text>
                  <strong>Role:</strong> {data.userDetail.role}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <h3>Akce</h3>
            <Card className="mb-3">
              <Card.Body>
                <ul className="list-unstyled">
                  <li>
                    <ImpersonationButton
                      userId={data.userDetail.id}
                    ></ImpersonationButton>
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <h3>Korespondenční adresa</h3>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>{data.userDetail.mailingAddress?.name}</Card.Title>
                <Card.Text>
                  {data.userDetail.mailingAddress?.street},{' '}
                  {data.userDetail.mailingAddress?.city},{' '}
                  {data.userDetail.mailingAddress?.zip},{' '}
                  {data.userDetail.mailingAddress?.state}
                </Card.Text>
                <Card.Text>
                  <strong>Telefon:</strong>{' '}
                  {data.userDetail.mailingAddress?.phone}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          {data.userDetail.contractAddress?.name && (
            <Col md={6}>
              <h3>Smluvní adresa</h3>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>
                    {data.userDetail.contractAddress?.name}
                  </Card.Title>
                  <Card.Text>
                    {data.userDetail.contractAddress?.street},{' '}
                    {data.userDetail.contractAddress?.city},{' '}
                    {data.userDetail.contractAddress?.zip},{' '}
                    {data.userDetail.contractAddress?.state}
                  </Card.Text>
                  <Card.Text>
                    <strong>Telefon:</strong>{' '}
                    {data.userDetail.contractAddress?.phone}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={12}>
            <h3>Adopce</h3>
            {data.userDetail.adoptions.map((adoption, index) => (
              <Card key={index} className="mb-3">
                {adoption.child && (
                  <>
                    <Card.Body>
                      <Card.Title>
                        <Card.Img
                          variant="top"
                          style={{ width: '60px', marginRight: '15px' }}
                          src={photoUrl(adoption.child.mainPhoto ?? '')}
                          alt={adoption.child.name}
                        />

                        <Link
                          style={{ color: 'darkgreen' }}
                          to={'/backoffice/adoption/' + adoption.id}
                        >
                          {adoption.child.name} ({adoption.child.fullNumber})
                        </Link>
                      </Card.Title>
                    </Card.Body>
                  </>
                )}
              </Card>
            ))}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3>Vydaná potvrzení o daru</h3>
            <Card className="mb-3">
              <Card.Body>
                {data.backofficeGiftCertificates.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <td>Rok</td>
                        <td>Částka</td>
                        <td>Stáhnout</td>
                        <td>Operace</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.backofficeGiftCertificates.map((certificate) => (
                        <tr key={certificate.id}>
                          <td>{certificate.year}</td>
                          <td>{certificate.price}</td>
                          <td>
                            {certificate.downloadAllowed && (
                              <AuthenticatedLink
                                url={`${getBackendUrl()}/adoption/gift-certificate/${certificate.id}`}
                                filename={`potvrzeni_o_daru_${certificate.year}_${certificate.id}.pdf`}
                              >
                                Stáhnout
                              </AuthenticatedLink>
                            )}
                          </td>
                          <td>
                            <Button
                              className="btn-sm"
                              onClick={() =>
                                handleClickDeleteCert(certificate.id)
                              }
                            >
                              Smazat
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>Žádné potvrzení nebylo vydáno.</div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
