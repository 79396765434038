import React from 'react';
import axios from 'axios';
import { getBackendUrl } from '../../tools';

interface ImageUploadProps {
  type: 'news' | 'child';
  childNumber?: string;
  onUploadFinished: (relativePath: string | undefined) => void;
}

export function ImageUploader(props: ImageUploadProps) {
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files === null || files.length < 1) {
      console.error('File missing.');
      return;
    }
    const file = files[0];

    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }

    try {
      const response = await axios.post(
        `${getBackendUrl()}/storage/upload-${props.type}-image?childNumber=${props.childNumber}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      console.log(`Image ${props.type} upload finished ${response.data}.`);
      props.onUploadFinished(response.data);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  return (
    <>
      <input type="file" accept="image/jpeg" onChange={handleFileChange} />
    </>
  );
}
